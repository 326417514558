<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="600"
        scrollable
    >
        <v-card ref="container">
            <v-card-title>{{ secret.title }} Details</v-card-title>
            <v-card-subtitle v-if="secret.description">{{ secret.description }}</v-card-subtitle>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <th>Latest version</th>
                            <td>{{ secret.meta.current_version }}</td>
                            <th>Max versions</th>
                            <td>{{ secret.meta.max_versions }}</td>
                        </tr>
                        <tr>
                            <th>Delete version after</th>
                            <td>{{ secret.meta.delete_version_after }}</td>
                            <th>Oldest version</th>
                            <td>{{ secret.meta.oldest_version }}</td>
                        </tr>
                        <tr>
                            <th>Created</th>
                            <td>{{ secret.meta.created_at_with_time_for_human }}</td>
                            <th>Last update</th>
                            <td>{{ secret.meta.updated_at_with_time_for_human }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
            <div class="tw-px-4 tw-mt-4 tw-flex tw-items-center tw-justify-between">
                <div class="tw-flex tw-items-center">
                    <h3 class="tw-text-sm">Secrets</h3>
                    <v-chip outlined small color="primary" class="tw-ml-2">Version {{ secret.version }}</v-chip>
                </div>
                <div class="tw-flex tw-items-center">
                    <v-btn
                        small
                        outlined
                        rounded
                        color="red"
                        @click="deleteVersion"
                        :loading="deletingVersion"
                        v-if="secret.deleted_at === null"
                    >
                        Delete version {{ secret.version }}
                        <template v-slot:loader>
                            <span>Deleting...</span>
                        </template>
                    </v-btn>
                    <v-menu
                    offset-y
                    left
                    v-if="secret.meta.versions.length > 1"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            outlined
                            small
                            rounded
                            light
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2"
                        >
                            History
                            <v-icon
                                dense
                                right
                            >fal fa-angle-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <template v-for="version in secret.meta.versions">
                            <v-list-item
                                :key="version.number"
                                link
                                v-if="version.number !== secret.version"
                                @click="switchVersion(version.number)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="tw-flex tw-items-center tw-justify-between">
                                        <div class="tw-mr-4">Version {{ version.number }}</div>
                                        <div>
                                            <v-icon
                                                title="Latest version"
                                                small
                                                color="green"
                                                v-if="version.number === secret.meta.current_version"
                                            >fal fa-check-circle</v-icon>
                                            <v-icon
                                                title="Deleted"
                                                small
                                                class="tw-ml-2"
                                                color="red"
                                                v-if="version.deleted_at !== null"
                                            >fal fa-times-circle</v-icon>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
                </div>
            </div>
            <v-simple-table v-if="secret.deleted_at === null">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th width="200">Key</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(secret, index) in secret.data"
                            :key="index"
                        >
                            <td>{{ secret.key }}</td>
                            <td>
                                <div class="tw-flex tw-items-center">
                                    <div>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    class="copy"
                                                    :aria-label="secret.value"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon small>fal fa-copy</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Copy</span>
                                        </v-tooltip>
                                        <v-tooltip left v-if="secret.key === 'totp'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    class="copy"
                                                    :aria-label="getOTP(secret, index)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :ref="`copyOtpButtons${index}`"
                                                >
                                                    <v-icon small>fal fa-phone-laptop</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Copy OTP</span>
                                        </v-tooltip>
                                        <v-tooltip
                                            left
                                            v-if="showableValues.findIndex((item) => item === index) !== -1"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    @click="hideValue(index)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon small>fal fa-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Hide</span>
                                        </v-tooltip>
                                        <v-tooltip
                                            left
                                            v-else
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    @click="showValue(index)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-icon small>fal fa-eye-slash</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </div>
                                    <div>
                                        <span v-if="showableValues.findIndex((item) => item === index) !== -1">
                                            {{ secret.value }}
                                        </span>
                                        <span v-else>***********</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div v-else-if="secret.is_destroyed === true" class="tw-px-8 tw-py-4 tw-text-center tw-text-sm">
                <h4>Version {{ secret.version }} of this secret has been permanently destroyed</h4>
                <p>A version that has been permanently deleted cannot be restored. You can see other versions of this secret in the History menu.</p>
            </div>
            <div v-else class="tw-px-8 tw-py-4 tw-text-center tw-text-sm">
                <h4>Version {{ secret.version }} of this secret has been deleted</h4>
                <p>A version that has been deleted but can be restore using the Restore button below. You can also see other versions of this secret in the History menu.</p>
                <div>
                    <v-btn
                        small
                        rounded
                        outlined
                        color="green"
                        @click="restoreVersion"
                        :loading="restoringVersion"
                        :disabled="deletingVersionPermanently"
                    >Restore</v-btn>
                    <v-btn
                        small
                        rounded
                        outlined
                        color="red"
                        class="tw-ml-2"
                        @click="deleteVersionPermanently"
                        :loading="deletingVersionPermanently"
                        :disabled="restoringVersion"
                    >Delete Permanently</v-btn>
                </div>
            </div>
            <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn
                    text
                    :disabled="restoringVersion || deletingVersionPermanently"
                    @click="close"
                >Close</v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
    import ClipboardJS from 'clipboard';
    import TOTP from '@/utils/totp';
    import Http from '@/utils/httpClient';

    export default {        
        data() {
            return {
                show: false,
                restoringVersion: false,
                deletingVersion: false,
                deletingVersionPermanently: false,
                loading: false,
                showableValues: [],
                secret: {
                    data: [],
                    meta: {
                        versions: [],
                    }
                }
            }
        },
        watch: {
            $route() {
                this.init();
            }
        },  
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if (this.$route.name === 'assets.secrets.details') {
                    this.open();
                }
            },
            open() {
                this.$root.$loading.open();
                Http.get(`secrets/${this.$route.params.secretId}`)
                    .then(({ data }) => {
                        this.$root.$loading.close();
                        this.secret = data.secret;
                        this.show = true;

                        setTimeout(() => {
                            // eslint-disable-next-line no-new
                            new ClipboardJS('.copy', {
                                text: function (trigger) {
                                    return trigger.getAttribute('aria-label');
                                },
                                container: this.$refs.container.$el
                            }).on('success', () => {
                                this.$root.$snackbar.open({
                                    text: 'Copied!',
                                    type: 'success',
                                });
                            })
                        }, 100);
                    })
                    .catch(() => {
                        this.close();
                        this.$root.$loading.close();
                    });
            },
            close() {
                this.show = false;
                this.$router.push({
                    name: 'assets.secrets',
                    query: this.$route.query,
                });
            },
            showValue(index) {
                this.showableValues.push(index);
            },
            hideValue(index) {
                const i = this.showableValues.findIndex((item) => item === index);
                this.showableValues.splice(i, 1);
            },
            switchVersion(version) {
                this.loading = true;
                Http.get(`secrets/${this.$route.params.secretId}?version=${version}`)
                    .then(({ data }) => {
                        this.loading = false;
                        this.secret = data.secret;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            restoreVersion() {
                this.$root.$confirm({
                    title: 'Are you sure?',
                    message: `You are about to restore version ${this.secret.version} of this secret.`,
                    agreeButtonText: `Yes, restore version ${this.secret.version}`,
                    agreeButtonColor: 'success',
                })
                .then(() => {
                    this.restoringVersion = true;
                    Http.delete(`secrets/${this.$root.$route.params.secretId}/restore-versions`, {
                        params: {
                            versions: [this.secret.version]
                        }
                    })
                        .then(() => {
                            this.restoringVersion = false;
                            this.$root.$snackbar.open({
                                text: `Version ${this.secret.version} was successfully restored.`,
                                type: 'success',
                            });
                            this.switchVersion(this.secret.version)
                        })
                        .catch(() => {
                            this.restoringVersion = false;
                        });
                })
                .catch(() => {
                    this.$root.$snackbar.open({
                        text: 'Action cancelled',
                        type: 'info',
                    });
                })
            },
            deleteVersion() {
                this.$root.$confirm({
                    title: 'Are you sure?',
                    message: `You are about to delete version ${this.secret.version} of this secret.`,
                    agreeButtonText: `Yes, delete version ${this.secret.version}`,
                    agreeButtonColor: 'error',
                })
                .then(() => {
                    this.deletingVersion = true;
                    Http.delete(`secrets/${this.$root.$route.params.secretId}/delete-versions`, {
                        params: {
                            versions: [this.secret.version]
                        }
                    })
                        .then(() => {
                            this.deletingVersion = false;
                            this.$root.$snackbar.open({
                                text: `Version ${this.secret.version} was successfully deleted.`,
                                type: 'success',
                            });
                            this.switchVersion(this.secret.version)
                        })
                        .catch(() => {
                            this.deletingVersion = false;
                        });
                })
                .catch(() => {
                    this.$root.$snackbar.open({
                        text: 'Action cancelled',
                        type: 'info',
                    });
                })
            },
            deleteVersionPermanently() {
                this.$root.$confirm({
                    title: 'Are you sure?',
                    message: `You are about to delete version ${this.secret.version} of this secret permanently.`,
                    agreeButtonText: `Yes, delete version ${this.secret.version} permanently`,
                    agreeButtonColor: 'error',
                })
                .then(() => {
                    this.deletingVersionPermanently = true;
                    Http.delete(`secrets/${this.$route.params.secretId}/delete-versions-permanently`, {
                        params: {
                            versions: [this.secret.version]
                        }
                    })
                        .then(() => {
                            this.deletingVersionPermanently = false;
                            this.$root.$snackbar.open({
                                text: `Version ${this.secret.version} was permanently deleted.`,
                                type: 'success',
                            });
                            this.switchVersion(this.secret.version)
                        })
                        .catch(() => {
                            this.deletingVersionPermanently = false;
                        });
                })
                .catch(() => {
                    this.$root.$snackbar.open({
                        text: 'Action cancelled',
                        type: 'info',
                    });
                })
            },
            getOTP(secret, key) {
                this.$nextTick(() => {
                    const el = this.$refs[`copyOtpButtons${key}`][0].$el;

                    setInterval(() => {
                        const epoch = Math.round(new Date().getTime() / 1000.0);
                        //const countDown = 30 - (epoch % 30);

                        if (epoch % 30 === 0) {
                            el.setAttribute('aria-label', (new TOTP()).getOtp(secret.value))
                        }
                    }, 1000)
                })

                return (new TOTP()).getOtp(secret.value);
            }
        }
    }
</script>
