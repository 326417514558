import JSSHA from 'jssha';

export default class Totp {
    // pass in the secret, code dom element, ticker dom element
    constructor(expiry = 30, length = 6) {
        this.expiry = expiry;
        this.length = length;
        // validate input
        if (this.length > 8 || this.length < 6) {
            throw new Error('Error: invalid code length');
        }
    }

    dec2hex(s) {
        return (s < 15.5 ? '0' : '') + Math.round(s).toString(16);
    }

    hex2dec(s) {
        return parseInt(s, 16);
    }

    base32tohex(base32) {
        const base32chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
        let bits;
        let chunk;
        let hex;
        let i;
        let val;

        bits = '';
        hex = '';
        i = 0;
        while (i < base32.length) {
            val = base32chars.indexOf(base32.charAt(i).toUpperCase());
            bits += this.leftpad(val.toString(2), 5, '0');
            i += 1;
        }
        i = 0;
        while (i + 4 <= bits.length) {
            chunk = bits.substr(i, 4);
            hex += parseInt(chunk, 2).toString(16);
            i += 4;
        }
        return hex;
    }

    leftpad(str, len, pad) {
        if (len + 1 >= str.length) {
            str = Array(len + 1 - str.length).join(pad) + str;
        }
        return str;
    }

    getOtp(secret, now = new Date().getTime()) {
        const epoch = Math.round(now / 1000.0);
        const key = this.base32tohex(secret);
        let offset;
        let otp;
        const shaObj = new JSSHA('SHA-1', 'HEX');
        const time = this.leftpad(this.dec2hex(Math.floor(epoch / this.expiry)), 16, '0');

        shaObj.setHMACKey(key, 'HEX');
        shaObj.update(time);
        const hmac = shaObj.getHMAC('HEX');
        // hmacObj = new jsSHA(time, "HEX")  # Dependency on sha.js
        // hmac = hmacObj.getHMAC(key, "HEX", "SHA-1", "HEX")
        if (hmac === 'KEY MUST BE IN BYTE INCREMENTS') {
            throw new Error('Error: hex key must be in byte increments');
        } else {
            // return null
            offset = this.hex2dec(hmac.substring(hmac.length - 1));
        }
        // eslint-disable-next-line no-bitwise
        otp = `${this.hex2dec(hmac.substr(offset * 2, 8)) & this.hex2dec('7fffffff')}`;
        if (otp.length > this.length) {
            otp = otp.substr(otp.length - this.length, this.length);
        } else {
            otp = this.leftpad(otp, this.length, '0');
        }
        return otp;
    }
}
